import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const getParams = () => {
  const { searchParams } = new URL(document.location.toString());
  return {
    planId: searchParams.get('id'),
    crmPlanId: searchParams.get('crmPlanId'),
    env: searchParams.get('env') || 'production',
    cursor: searchParams.get('cursor') || 'pointer',
  };
};

const createWidget = async () => {
  const { planId } = getParams();

  const apiUrl = WIDGET_API_URL;
  const apiHandler = new ApiStore(apiUrl);
  const planData = await apiHandler.loadWidgetData(planId);

  const options = {
    ...planData.parsed,
    API_URL: apiUrl,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    locale: 'ru',
    colors: {
      main: '#027bfb',
      mainText: '#ffffff'
    },
    forceTopViewOnMap: true,
    availableRotationSteps: ['carousel', 'topView'],
    designType: 'standard',
    tabsButtonsDesign: 'two'
  }

  new Widget('#widget', options);
}

createWidget();
